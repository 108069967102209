.repeatable-item__component {
  /* Some Anatomic components (ie. Boolean buttons) rely on their parents size to determine their content size */
  /* When put inside a flex container it creates a recursive size between parent + child */
  /* Setting an explicit size then letting it grow fixes this */
  width: 10px;
  flex-grow: 1;
}

.repeatable-item__remove-button {
  border: none;
  background: none;
  padding: 0;
  width: var(--dvavbi7);
}
