body {
  margin: 0;
  font-family: Poppins, Arial, Helvetica, sans-serif;
}

.watermark-container {
  height: 100%;
  position: absolute;
  width: 100%;
}

.watermark {
  font-size: 72px;
  left: 50%;
  opacity: 0.1;
  position: absolute;
}

.watermark:nth-of-type(1) {
  top: 5%;
  transform: translate(-50%, 20%) rotate(-30deg);
}

.watermark:nth-of-type(2) {
  top: 50%;
  transform: translate(-50%, -50%) rotate(-30deg);
}

.watermark:nth-of-type(3) {
  top: 95%;
  transform: translate(-50%, -120%) rotate(-30deg);
}

@media screen and (min-width: 768px) {
  .watermark {
    font-size: 128px;
  }
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

.hide-scrollbar {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}

.hide-scrollbar::-webkit-scrollbar {
  /* WebKit */
  width: 0;
  height: 0;
}

.picker-container {
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0px 4px 50px 0px #0071bc1a;
  width: fit-content;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 1rem;
}

/*Helpers*/

.mt-4 {
  margin-top: 1rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mt-12 {
  margin-top: 3rem;
}

.support-link-icon > svg path {
  fill: rgb(0, 113, 188) !important;
}

.support-link-icon:hover > svg path {
  fill: #3f93cc !important;
}

p,
ul {
  margin-block-start: 0;
  margin-block-end: 0;
}

* + p,
* + ul {
  margin-block-start: 1em;
}

/* Removes empty children from the stack list */
.group-children-stack > div:empty {
  display: none !important;
}

.label-heading {
  font-size: 1.25rem !important;
}

.inline-block {
  display: inline-block;
}

.loader {
  display: 'flex';
  justify-content: 'center'
}

.loader:focus {
  outline: none;
}
