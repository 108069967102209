/* hiding ripples makes it feel less Material */
.MuiTouchRipple-root {
  display: none;
}

/* default to body's font-family */
.MuiButtonBase-root {
  font-family: unset !important;
}

.MuiMultiSectionDigitalClock-root {
  border-bottom: none !important;
  padding: 16px 16px 16px 16px;
}

.MuiMultiSectionDigitalClock-root {
  max-height: 319px !important; /* matches the date picker*/
}

/* give the clock parts a circle background to match the calendar picker */
.MuiMultiSectionDigitalClock-root li {
  border-radius: 999px;
  height: 32px;
  min-height: unset;
  width: 32px;
  color: var(--dvavbi7k); /* taken from Anatomic theme */
  background-color: #ddedfb; /* taken from Anatomic theme */
  margin: 6px 4px;
}
.MuiMultiSectionDigitalClock-root li:hover,
.MuiMultiSectionDigitalClock-root li:focus {
  background-color: var(--dvavbi37); /* taken from Anatomic theme */
}
.MuiMultiSectionDigitalClock-root li.Mui-selected {
  background-color: var(--dvavbi7k); /* taken from Anatomic theme */
  color: white;
  font-weight: bold;
}

/* disable scrolling for AM/PM column */
.MuiMultiSectionDigitalClock-root ul {
  overflow: hidden;
}
.MuiMultiSectionDigitalClock-root ul:after {
  content: unset;
}
