.partialInput-container {
  box-sizing: border-box;
  cursor: text;
  position: relative;
  height: auto !important;
}

.partialSeparator {
  user-select: none;
}

.partialInput {
  display: inline;
  position: relative;
}

.partialInput span {
  user-select: none;
}

.partialInput input {
  border: none;
  box-shadow: none !important;
  padding-left: 0;
  padding-right: 0;
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
}

.unstyled-input {
  border: none !important;
  box-shadow: none !important;
  padding-left: 0 !important;
  width: 100% !important;
}
