.DayPicker_transitionContainer {
  min-height: 318px;
}

.CalendarDay {
  font-size: unset;
}

.CalendarDay__default {
  position: relative;
  color: var(--dvavbi7k); /* taken from Anatomic theme */
  background: white;
}
.CalendarDay__default:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  margin-left: auto;
  margin-right: auto;
  width: 2rem;
  height: 2rem;
  border-radius: 100%;
  border-style: none;
  padding-top: 0.5rem;
  text-align: center;
  line-height: 1.6;
  z-index: -1;

  background-color: #ddedfb; /* taken from Anatomic theme */
}
.CalendarDay__today:before {
  background-color: var(--dvavbi2t); /* taken from Anatomic theme */
}
.CalendarDay__default:hover::before {
  background-color: var(--dvavbi37); /* taken from Anatomic theme */
}

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background: var(--dvavbi7k); /* taken from Anatomic theme */
  color: white;
  font-weight: bold;
}
.CalendarDay__selected:before {
  background-color: var(--dvavbi7k); /* taken from Anatomic theme */
}
.CalendarDay__selected:hover::before {
  background-color: var(--dvavbi38); /* taken from Anatomic theme */
}

.CalendarDay__blocked_out_of_range {
  opacity: 0.25;
}

.CalendarDay__blocked_out_of_range,
.CalendarDay__blocked_out_of_range:active,
.CalendarDay__blocked_out_of_range:hover {
  color: var(--dvavbi7k); /* taken from Anatomic theme */
}
.CalendarDay__blocked_out_of_range:before {
  background-color: transparent;
}
.CalendarDay__blocked_out_of_range:hover::before {
  background-color: transparent;
}

.CalendarMonth_caption {
  color: var(--dvavbi7k); /* taken from Anatomic theme */
  text-align: left;
  font-size: unset;
  line-height: 1.6;
}

.calendar__prev,
.calendar__next {
  position: absolute;
  top: 1.5rem;
}

.calendar__prev {
  right: 3rem;
}

.calendar__next {
  right: 0.75rem;
}

.CalendarDay:focus {
  outline: 2px dotted var(--dvavbi7k); /* taken from Anatomic theme */
  outline-offset: 2px;
}

.DayPicker_weekHeader_li small {
  font-size: unset;
  line-height: 1.3;
  --tw-text-opacity: 1;
  color: rgba(57, 57, 57, var(--tw-text-opacity));
}

.DayPicker,
.CalendarMonthGrid,
.CalendarMonth {
  background-color: transparent !important;
}

.CalendarDay__default {
  border-style: none;
  background-color: transparent;
}
.CalendarDay__default:hover {
  border-style: none;
  background-color: transparent;
}
