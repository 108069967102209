.input-fieldset {
  display: contents;
  border: none;
  padding: 0;
  margininlinestart: 0;
  margininlineend: 0;
  paddingblockstart: 0;
  paddinginlinestart: 0;
  paddinginlineend: 0;
  paddingblockend: 0;
  mininlinesize: unset;
}
