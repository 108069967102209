.reference-input__input-field {
  border-bottom-right-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.reference-input__toggle-container {
  padding-bottom: .5rem !important;
  padding-top: .5rem !important;
}

.reference-input__reference-button {
  border-radius: 0 8px 8px 0 !important;
  height: 56px !important;
}

.reference-card {
  margin-top: 1rem !important;
  position: relative;
}

.reference-card__display {
  white-space: pre-line;
}

.reference-card__remove-button {
  position: absolute;
  right: 1rem;
  top: 1rem;
}

